import { useEffect } from "react";

function App() {

  useEffect(() => {
    window.triggerScroll();
  }, []);

  return (
    <div className="container h-100 flex-column d-flex justify-content-between">

      <div className="row pt-5" animate="left">
        <div className="col">
          <img src="/assets/images/logo.svg" alt="logo" />
        </div>
      </div>

      <div className="row py-lg-5 py-3 align-items-center justify-content-between">
        <div className="col-lg-5 col-md-5" animate="left">
          <h1 className="mb-lg-5 mb-3">Something Awesome Is In The Works</h1>
          <p className="mb-4">Meanwhile we can handle a full range of strategic online marketing services.
            Contact us for business inquiries.</p>
        </div>
        <div className="col-lg-5 col-md-5" animate="right">
          <div class="float-area">
            <div className="ratio image-ratio">
              <img className="floating-img" src="/assets/images/laptop.png" alt="image" />
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-between pb-5">
        <div className="col-md-auto col-sm-12 mb-sm-4 mb-4">
          <div className="social-icons">
            <a href="mailto:lea@thedigitalalert.com" target="_blank" rel="noreferrer">
              <img className="me-3" src="./assets/images/mail.svg" alt="icon" />
            </a>
            <a href="https://www.instagram.com/thedigitalalert/" target="_blank" rel="noreferrer">
              <img className="me-3" src="./assets/images/insta.svg" alt="icon" />
            </a>
            <a href="https://www.facebook.com/thedigitalalert" target="_blank" rel="noreferrer">
              <img className="me-3" src="./assets/images/fb.svg" alt="icon" />
            </a>
          </div>
        </div>
        <div className="col-md-auto col-sm-12">
          <p>The Digital Alert | Jounieh, Ghadir, St Elie Building</p>
        </div>
        <div className="col-md-auto col-sm-12">
          <a className="phone-number" href="tel:96178832533" target="_blank" rel="noreferrer">
            +961 78 832 533
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
